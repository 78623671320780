// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-components-components-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\Components\\Components.jsx" /* webpackChunkName: "component---src-pages-components-components-jsx" */),
  "component---src-pages-components-sections-section-basics-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\Components\\Sections\\SectionBasics.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-basics-jsx" */),
  "component---src-pages-components-sections-section-carousel-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\Components\\Sections\\SectionCarousel.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-carousel-jsx" */),
  "component---src-pages-components-sections-section-completed-examples-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\Components\\Sections\\SectionCompletedExamples.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-completed-examples-jsx" */),
  "component---src-pages-components-sections-section-download-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\Components\\Sections\\SectionDownload.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-download-jsx" */),
  "component---src-pages-components-sections-section-examples-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\Components\\Sections\\SectionExamples.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-examples-jsx" */),
  "component---src-pages-components-sections-section-javascript-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\Components\\Sections\\SectionJavascript.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-javascript-jsx" */),
  "component---src-pages-components-sections-section-login-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\Components\\Sections\\SectionLogin.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-login-jsx" */),
  "component---src-pages-components-sections-section-navbars-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\Components\\Sections\\SectionNavbars.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-navbars-jsx" */),
  "component---src-pages-components-sections-section-notifications-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\Components\\Sections\\SectionNotifications.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-notifications-jsx" */),
  "component---src-pages-components-sections-section-pills-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\Components\\Sections\\SectionPills.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-pills-jsx" */),
  "component---src-pages-components-sections-section-tabs-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\Components\\Sections\\SectionTabs.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-tabs-jsx" */),
  "component---src-pages-components-sections-section-typography-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\Components\\Sections\\SectionTypography.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-typography-jsx" */),
  "component---src-pages-index-js": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-js": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-landing-page-landing-page-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\LandingPage\\LandingPage.jsx" /* webpackChunkName: "component---src-pages-landing-page-landing-page-jsx" */),
  "component---src-pages-landing-page-sections-product-section-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\LandingPage\\Sections\\ProductSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-product-section-jsx" */),
  "component---src-pages-landing-page-sections-team-section-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\LandingPage\\Sections\\TeamSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-team-section-jsx" */),
  "component---src-pages-landing-page-sections-work-section-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\LandingPage\\Sections\\WorkSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-work-section-jsx" */),
  "component---src-pages-login-page-js": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\login-page.js" /* webpackChunkName: "component---src-pages-login-page-js" */),
  "component---src-pages-login-page-login-page-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\LoginPage\\LoginPage.jsx" /* webpackChunkName: "component---src-pages-login-page-login-page-jsx" */),
  "component---src-pages-profile-page-js": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\profile-page.js" /* webpackChunkName: "component---src-pages-profile-page-js" */),
  "component---src-pages-profile-page-profile-page-jsx": () => import("C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\src\\pages\\ProfilePage\\ProfilePage.jsx" /* webpackChunkName: "component---src-pages-profile-page-profile-page-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\wamp64\\www\\github-repos\\appseed\\jamstack\\gatsbyjs-material-kit\\.cache\\data.json")

